import React from 'react'

import Slider from "react-slick";
import OurSolutionImg from '../images/success-deliver-excellence.jpg';
import CodeReviewImg from '../images/success-affective-commitment.jpg'
import BestPlaceImg from '../images/success-adapt-and-evolve.jpg'
const OurApproachToSuccessSliderComponent = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };
    return (
        <section className="container mx-auto border-b py-12">
            <div className="antialiased text-gray-900 font-sans p-6">
                <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                    Our Approach to Success
                </h2>
                <div className="w-full mb-16">
                    <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>

                <div className="mx-auto">
                    <Slider {...settings}>
                        <div className='border-primary border-0 rounded-md h-full'>
                            <div className='flex flex-row  w-full'>

                                <div className='bg-primary flex-col flex-1 w-full text-white p-10 '>
                                    <h2 className='font-bold text-4xl py-10'>Affective Commitment to Clients</h2>
                                    <div>
                                        <p className="text-lg">
                                            With our “Clients First” approach, we strive to be of value to
                                            our clients and not just a success. We are invested in
                                            creating client relationships through our software product and
                                            services that grow stronger and last longer.
                                        </p>
                                    </div>
                                </div>
                                <div className='hidden md:flex flex-1 justify-center'>
                                    <img src={CodeReviewImg} className="h-96 object-cover w-full" />
                                    <a className='text-sm hidden w-full text-right px-2 text-slate-200'
                                        href="https://www.freepik.com/free-vector/company-has-achieved-successful-business-deal_14245105.htm#query=commitment&position=6&from_view=search#position=6&query=commitment">
                                        Image by jcomp on Freepik
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='border-primary border-0 rounded-md h-full'>
                            <div className='flex flex-row w-full'>

                                <div className='bg-primary flex-col w-full text-white p-10 flex-1'>
                                    <h2 className='font-bold text-4xl pt-10'>Our way of delivering excellence is “HAS” -</h2>
                                    <div>
                                        <div className="p-4  text-xs text-center text-gray-100">
                                            <p className="text-xl">High performance</p>
                                            <div className="w-full">
                                                <div className="h-1 mx-auto bg-gray-100 w-32 opacity-25 my-0 py-0 rounded-t"></div>
                                            </div>
                                        </div>

                                        <div className="p-4 text-xs text-center text-gray-100">
                                            <p className="text-xl">Accountable</p>
                                            <div className="w-full">
                                                <div className="h-1 mx-auto bg-gray-100 w-28 opacity-25 my-0 py-0 rounded-t"></div>
                                            </div>
                                        </div>

                                        <div className="p-4 text-xs text-center text-gray-100">
                                            <p className="text-xl">Strategic</p>
                                            <div className="w-full">
                                                <div className="h-1 mx-auto bg-gray-100 w-28 opacity-25 my-0 py-0 rounded-t"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='hidden md:flex w-full flex-1 justify-center'>
                                    <img src={OurSolutionImg} className="h-96 object-cover w-full" />
                                    <a className='text-sm hidden w-full text-right px-2 text-slate-200'
                                        href="https://www.freepik.com/free-vector/company-has-achieved-successful-business-deal_14245105.htm#query=commitment&position=6&from_view=search#position=6&query=commitment">Image by jcomp on Freepik</a>
                                </div>
                            </div>
                        </div>
                        <div className='border-primary border-0 rounded-md h-full'>
                            <div className='flex flex-row w-full'>

                                <div className='bg-primary flex-col flex-1 w-full text-white p-10 '>
                                    <h2 className='font-bold text-4xl py-10'>Adapt and Evolve</h2>
                                    <div>
                                        <p className="text-lg">
                                            In this ever-changing world, we firmly believe that enjoying
                                            success requires the ability to adapt. Instead of approaching
                                            “change” as one of our projects, we have embedded
                                            change-readiness into our culture, decisions, expectations and
                                            capabilities. We adapt and evolve quickly and continuously.
                                        </p>
                                    </div>
                                </div>
                                <div className='hidden md:flex w-full flex-1 justify-center'>
                                    <img src={BestPlaceImg} className="h-96 object-cover w-full" />
                                    <a className='text-sm hidden w-full text-right px-2 text-slate-200'
                                        href="https://www.freepik.com/free-vector/company-has-achieved-successful-business-deal_14245105.htm#query=commitment&position=6&from_view=search#position=6&query=commitment">Image by jcomp on Freepik</a>
                                </div>
                            </div>
                        </div>
                    </Slider>

                </div>
            </div>
        </section>
    )
}

export default OurApproachToSuccessSliderComponent
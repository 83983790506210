import * as React from "react";
import Logo from "../images/fourlink_logo_color.png";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
// import SolutionWeOfferComponent from "../components/SolutionsWeOfferComponent";
import EngagementComponent from "../components/EngagementComponent";
// import OurApproachToSuccessComponent from "../components/OurApproachToSuccessComponent";
import BannerComponent from "../components/BannerComponent";
import WhatWeDoComponent from "../components/WhatWeDoComponent";
import MissionComponent from "../components/MissionComponent";
import ValuePropotionComponent from "../components/ValuePropotionComponent";
import SolutionWeOfferNewComponent from "../components/SolutionWeOfferNewComponent";
import OurApproachToSuccessNewComponent from "../components/OurApproachToSuccessNewComponent";
import OurApproachToSuccessSliderComponent from "../components/OurApproachToSuccessSliderComponent";

const IndexOldPage = () => {





  return (
    <div className="leading-normal tracking-normal text-white gradient">
      <Navbar />

      <BannerComponent />

      <div className="w-full bg-white">
        <SolutionWeOfferNewComponent />
      </div>

      {/* <SolutionWeOfferComponent /> */}

      {/* <WhatWeDoComponent /> */}

      {/* <OurApproachToSuccessComponent /> */}

      <div className="w-full bg-white">
        {/* <OurApproachToSuccessNewComponent /> */}
        <OurApproachToSuccessSliderComponent />
      </div>
      
      
      

      <EngagementComponent />

      {/* <ValuePropotionComponent /> */}

      <MissionComponent />
      <Footer />
    </div>
  )
}

export default IndexOldPage

export const Head = () => <title>FourLink</title>

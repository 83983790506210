import React from "react";
import OurSolutionImg from '../images/services_offered_3.png';
import CodeReviewImg from '../images/services_offered.png'
import BestPlaceImg from '../images/services_offered_2.png'

const SolutionWeOfferNewComponent = () => {
  return (
    <section className="container mx-auto border-b py-12">
      <div className="antialiased text-gray-900 font-sans p-6">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
          Solutions We Offer
        </h2>
        <div className="w-full mb-16">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div className="mx-auto">
          <div className="flex gap-4">
            <div className="w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4 border-2 rounded-md border-primary">
              <div className="relative overflow-hidden">
                <img src={CodeReviewImg} className="w-full object-cover" />
              </div>
              <div className="p-4">
                <h2 className="w-full my-2 text-2xl font-bold leading-tight text-gray-800">
                  Software Solutions
                </h2>
              </div>

              <div className="p-4 border-b text-xs text-gray-700">
                <p className="text-lg">
                  Software Solutions
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  Custom web and mobile application development
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  Custom enterprise software solutions
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  Application and Technology modernization and migrations
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  Database as well as Big Data design and management and data
                  visualization
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  API development and integrations
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  IoT integrations
                </p>
              </div>

              <div className="p-4 border-t text-xs text-gray-700">
                <p className="text-lg">
                  ML/AI service integrations
                </p>
              </div>

            </div>

            <div className="w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4 border-2 rounded-md border-primary">

              <div className="relative overflow-hidden">
                <img src={BestPlaceImg} className="w-full object-cover" />
              </div>
              <div className="p-4">
                {/* <h2 className="mt-2 mb-2  font-bold">
                  Purus Ullamcorper Inceptos Nibh
                </h2> */}
                <h2 className="w-full my-2 text-2xl font-bold leading-tight text-gray-800">
                  Infrastructure Solutions
                </h2>
                {/* <p className="text-sm">
                Custom web and mobile application development
                </p> */}
              </div>

              <div className="p-4 border-b text-xs text-gray-700">
                <p className="text-lg">
                  Cloud infrastructure Architecture design, deployment and
                  maintenance
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  Cloud Infrastructure Maintenance
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  Cloud Infrastructure Teraforming
                </p>
              </div>

              <div className="p-4 border-t text-xs text-gray-700">
                <p className="text-lg">
                  Cloud Infrastructure Migration
                </p>
              </div>

            </div>




            <div className="w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4 border-2 rounded-md border-primary">

              <div className="relative overflow-hidden">
                <img src={OurSolutionImg} className="w-full object-cover" />
              </div>
              <div className="p-4">
                {/* <h2 className="mt-2 mb-2  font-bold">
                  Purus Ullamcorper Inceptos Nibh
                </h2> */}
                <h3 className="text-2xl text-gray-800 font-bold leading-none mb-3">
                  Software Product Solutions
                </h3>
                {/* <p className="text-sm">
                Custom web and mobile application development
                </p> */}
              </div>





              <div className="p-4 border-b text-xs text-gray-700">
                <p className="text-lg">
                  Idea to market
                </p>
              </div>

              <div className="p-4 border-t border-b text-xs text-gray-700">
                <p className="text-lg">
                  Product design and development for our clients
                </p>
              </div>

              <div className="p-4 border-t text-xs text-gray-700">
                <p className="text-lg">
                  Product management for our clients
                </p>
              </div>

            </div>

          </div>
        </div>
      </div>
    </section>

  );
};

export default SolutionWeOfferNewComponent;

import React from "react";

const EngagementComponent = () => {
  return (
    <section className="text-gray-600 body-font bg-white">
      <div className="px-5 py-24  mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Our engagement models
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Banh mi cornhole echo park skateboard authentic crucifix neutra tilde lyft biodiesel artisan direct trade mumblecore 3 wolf moon twee</p> */}
        </div>
        <div className="lg:w-2/3 w-full mx-auto overflow-auto">
          <table className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th className="px-4 pt-3 pb-20 title-font tracking-wider font-medium text-gray-900 text-sm rounded-tl rounded-bl"></th>
                <th className="px-4 title-font tracking-wider font-medium text-gray-900 text-xl text-center bg-gray-100">
                  Fixed Price
                  <div className="h-1 bg-slate-300 my-4 px-6 w-16 mx-auto"></div>
                </th>
                <th className="px-4 title-font tracking-wider font-medium text-gray-900 text-xl text-center">
                  Time and Material
                  <div className="h-1 bg-slate-300 my-4 px-6 w-16 mx-auto"></div>
                </th>
                <th className="px-4 title-font tracking-wider font-medium bg-gray-100 text-gray-900 text-xl text-center">
                  Dedicated Team
                  <div className="h-1 bg-slate-300 my-4 px-6 w-16 mx-auto"></div>
                </th>
                <th className="w-10 title-font tracking-wider font-medium text-gray-900 text-sm  rounded-tr rounded-br"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-8 text-lg text-gray-900 text-right">Size of project</td>
                <td className="px-4 py-3 text-center bg-gray-100">Small to medium</td>
                <td className="px-4 py-3 text-center">Small to medium</td>
                <td className="px-4 py-3 text-center bg-gray-100">Large</td>
                <td className="w-10 text-center"></td>
              </tr>
              <tr>
                <td className="border-width-5 border-gray-200 px-4 py-6 text-lg text-gray-900 text-right">
                  Requirements
                </td>
                <td className="border-t-2 border-gray-200 px-4 py-3 text-center bg-gray-100">Predefined</td>
                <td className="border-t-2 border-gray-200 px-4 py-3 text-center">
                  Not Defined
                </td>
                <td className="border-t-2 border-gray-200 px-4 py-3 text-center bg-gray-100">Evolving</td>
              </tr>
              <tr>
                <td className=" border-gray-200 px-4 py-8 text-lg text-gray-900 text-right">
                  Flexibility
                </td>
                <td className="border-t-2 border-gray-200 px-4 py-3 text-center bg-gray-100">Little</td>
                <td className="border-t-2 border-gray-200 px-4 py-3 text-center ">High</td>
                <td className="border-t-2 border-gray-200 px-4 text-center bg-gray-100">High</td>
              </tr>
              <tr>
                <td className=" border-gray-200 px-4 py-6 text-lg text-gray-900 text-right">
                  Budget
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center bg-gray-100">
                  Predefined
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center">
                  Not Set
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center bg-gray-100">
                  Estimated
                </td>
              </tr>
              <tr>
                <td className=" border-gray-200 px-4 py-6 text-lg text-gray-900 text-right w-72">
                  Client’s control on changes in requirements
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center bg-gray-100">
                  Little
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center">
                  Significant
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center bg-gray-100">
                  Full or Partial
                </td>
              </tr>
              <tr>
                <td className=" border-gray-200 px-4 py-6 text-lg text-gray-900 text-right">
                  Methodology
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center bg-gray-100">
                  Waterfall
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center">
                  Agile
                </td>
                <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-center bg-gray-100">
                  Agile
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-end pl-4 mt-4 lg:w-2/3 w-full mx-auto">
          <a href="/contactus" className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};

export default React.memo(EngagementComponent);
